/* Fade-in effect */
.fade-in {
  opacity: 0;
  transition: opacity 2s ease-in;
}

.show {
  opacity: 0.8;
}

.backgroundImage {
  position: fixed;
  top: -40vh;
  left: -10vw;
  width: 110%;
  height: 140%;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.overlayTextCenter {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 90px;
  color: white;
  text-align: center;
  z-index: 1; /* Ensures it stays on top of other elements */
  pointer-events: none; /* Makes sure it doesn't interfere with user interactions */
}
