.donation-box {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  position: relative;
}

.donation-box h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.form-row .form-group {
  flex: 1;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.donation-box button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.donation-box button:hover {
  background-color: #218838;
}

.receipt-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  padding: 15px;
  border-radius: 5px;
  opacity: 0;
  animation: fadeIn 1s forwards;
  text-align: center; /* Center the text */
}

.receipt-box button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-top: 10px; /* Add margin between text and button */
  border-radius: 4px;
  cursor: pointer;
}

.receipt-box button:hover {
  background-color: #218838;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
