.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --primary: #04bbd3;
  --primaryDark: #1c9cad;
  --secondary: rgb(33, 33, 33);
  --tertiary: #d39127;
  --vidNav: #3394d4d0;
  --letter: #f0b22d;
  --u2Col: rgb(255, 91, 91);
  --bg: white;
  --textColor: #1c9cad;
  --textHover: #2b7781;
  --textFont: "Nunito Sans";
  --fontSecondary: "aktiv-grotesk-std", sans-serif;
}

body {
  padding: 0;
  margin: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-width: 100%;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

a {
  text-decoration: none;
}
