.titleCard {
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    flex-direction: column;
    background-color: white;
    color: rgb(33, 33, 33);
    font-family: Verdana;
    font-size: 12vh;
}