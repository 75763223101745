.abtBox {
  width: 100%;
  height: 115vh;
  background-color: var(--bg);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 30;
  position: relative;
}

.abtLeft {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 30;
}

.abtRight {
  background-color: mediumseagreen;
  width: 0%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lHead {
  width: 80%;
  height: auto;
  text-align: left;
  color: var(--tertiary);
  font-family: var(--fontSecondary);
  font-weight: 700;
  font-style: normal;
  font-size: 2vh;
  letter-spacing: 0.05em;
  line-height: 1.2em;
  text-rendering: optimizeLegibility;
}

.lMotto {
  width: 80%;
  height: auto;
  text-align: left;
  color: var(--textColor);
  font-family: var(--fontSecondary);
  font-weight: 700;
  font-style: normal;
  font-size: 5vh;
  line-height: 1em;
  text-rendering: optimizeLegibility;
  margin: 10px 0;
}

.lText {
  width: 80%;
  height: auto;
  text-align: Left;
  color: var(--textColor);
  font-family: var(--fontSecondary);
  font-weight: 400;
  font-style: normal;
  font-size: 2vh;
  line-height: 1.4em;
  text-rendering: optimizeLegibility;
  margin: 20px 0;
}

.contentA {
  position: relative;
  z-index: 1;
  padding: 50px;
  padding-top: 30px;
  max-width: 750px;
  margin: 0 auto;
  margin-top: 100vh;
  margin-bottom: 10vh;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.aboutSection {
  margin-top: 0vh;
}

.aboutSection h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.aboutSection p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}
