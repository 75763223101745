.form-container {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  z-index: 10;
}

h1 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-row input {
  width: 48%;
}

input,
textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  height: 150px;
  resize: vertical;
}

button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.contentC {
  position: relative; /* Required for absolute positioning of the triangle */
  padding: 50px;
  padding-top: 60px; /* Increased padding-top to make room for the triangle */
  max-width: 950px;
  margin: 0 auto;
  margin-top: 100vh;
  margin-bottom: 10vh;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 10;
  display: flex; /* Enable Flexbox */
  align-items: center; /* Vertically center the child */
  justify-content: center; /* Horizontally center the child */
  flex-direction: column; /* Stack children vertically */
}

.contentC::before {
  content: "Want to get in touch?";
  position: absolute;
  top: -30px; /* Adjust this value to move the triangle up or down */
  left: 50%;
  transform: translateX(-50%);
  background-color: #face0b; /* Darkish yellow color */
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  z-index: 2;
  text-shadow: -1px -1px 0 #b68500, 1px -1px 0 #b68500, -1px 1px 0 #c5c5c5,
    1px 1px 0 #b68500;
}

.contentC::after {
  content: "";
  position: absolute;
  top: -30px; /* Same value as before */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid #face0b; /* Darkish yellow color */
  z-index: 1;
}
