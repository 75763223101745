.backgroundImageH {
  position: fixed;
  top: -5vh;
  left: -10vw;
  width: 110%;
  height: 105%;
  background-image: url("https://images.pexels.com/photos/3589228/pexels-photo-3589228.jpeg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.overlayText {
  position: absolute;
  top: 40%;
  right: 5%;
  color: white;
  text-align: right;
  max-width: 320px;
  z-index: 1;
}

.overlayText h1 {
  font-size: 30px;
  margin-bottom: 10px;
  text-align: right;
}

.overlayText p {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: right;
}

.ctaButton {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ctaButton:hover {
  background-color: #0056b3;
}

/* Fade-in effect */
.fade-in {
  opacity: 0;
  transition: opacity 2s ease-in;
}

.show {
  opacity: 0.8;
}
