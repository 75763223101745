body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
}

.homeContainer {
  position: relative;
  height: 100vh;
  width: 100%;
}
