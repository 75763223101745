.navBox {
  width: 100vw;
  height: 90px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  top: 0px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: solid #929292;
  border-width: 0px 0px 2px 0px;
  margin-top: 0px;
  margin-bottom: 2px;
}

.logoBox {
  height: 90px;
  margin: 0 1% 0 1%;
}

.logoBox:hover {
  cursor: pointer;
}

.navLinks {
  width: 85px;
  height: 100%;
  margin: 0 1% 0 1%;
  font-family: Arial, sans-serif;
  color: var(--textColor);
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
}

.navLinks:hover {
  color: var(--textHover);
}
