/* Video Section Styles */
.videoSection {
  margin-top: 50px;
}

.searchBar {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.videoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.videoCard {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-bottom: 16%;
}

.videoCard video {
  width: 100%;
  height: 100%;
}

.videoCard h2 {
  font-size: 18px;
  margin: 10px 18px;
  color: black;
}

.content {
  position: relative;
  z-index: 1;
  padding: 50px;
  padding-top: 30px;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 100vh;
  margin-bottom: 10vh;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.aboutSection {
  margin-top: 0vh;
}

.aboutSection h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.aboutSection p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}
