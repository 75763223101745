.dfInstr {
  width: 100vw;
  height: 8vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 51;
}

.dfiText {
  text-align: center;
  font-family: var(--textFont);
  color: var(--secondary);
  font-size: 20px;
  position: relative;
  z-index: 55;
}

.dfBox {
  width: 52.4vw;
  height: 35vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: verdana;
  position: relative;
  z-index: 51;
  border-radius: 15px;
  overflow: hidden;
  perspective: 1px;
}

.formBox {
  height: 30vh;
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: relative;
  z-index: 51;
}

.nameBox,
.emailBox {
  height: 8vh;
  width: 40vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.name {
  width: 24.5vw;
  height: 4vh;
  border-radius: 3px;
  background-color: white;
  outline: none;
  border: 1px solid rgb(33, 33, 33);
  font-size: 1.5vh;
  margin: 2vh;
  padding-left: 10px;
  font-family: var(--textFont);
}

.email {
  width: calc(50vw + 3.5vh);
  height: 4vh;
  border-radius: 3px;
  background-color: white;
  padding-left: 10px;
  margin: 2vh;
  outline: none;
  border: 1px solid rgb(33, 33, 33);
  font-size: 1.5vh;
  font-family: var(--textFont);
}

.btnBox {
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn {
  width: 6vw;
  height: 5vh;
  border-radius: 3px;
  background-color: white;
  color: var(--primaryDark);
  border: 2px solid var(--primary);
  font-weight: bold;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--primaryDark);
  color: white;
}
