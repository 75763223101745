.container {
  width: 100%;
  height: 95vh;
  background-color: white;
  padding-top: 70px;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 52;
}

.tabbedBox {
  width: 100%;
  height: 87vh;
  background-color: #a7cfd4;
  overflow: hidden;
  perspective: 1px;
  border-radius: 15px;
}

.tabs {
  white-space: nowrap;
}

.tab {
  display: inline-block;
  cursor: pointer;
  width: 50%;
  background-color: #6eaab3;
}

.tabHeader {
  padding: 10px 20px;
  text-align: center;
  color: white;
}

.tabHeader.active {
  border-bottom: 2px solid white;
}

.tabContent {
  padding: 20px;
}

.donation-form {
  width: 30vw;
  margin: 0 auto;
  margin-top: 2%;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: white;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.content {
  position: relative;
  z-index: 1;
  padding: 50px;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 100vh;
  margin-bottom: 10vh;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.aboutSection {
  margin-top: 0vh;
}

.aboutSection h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.aboutSection p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}
