.footBox {
  border-top: solid rgb(70, 70, 70);
  border-width: 2px;
  height: 70px;
  width: 100%;
  background-color: rgb(33, 33, 33);
}

.footLeft {
  width: 50%;
  height: 100%;
  float: left;
}

.footRight {
  width: 50%;
  height: 100%;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: var(--textFont);
}
